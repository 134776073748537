<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M11.9719 6.97188L11.75 7V5.25C11.75 4.83594 11.4141 4.5 11 4.5C10.5859 4.5 10.25 4.83594 10.25 5.25V7H7.75V5.25C7.75 4.83594 7.41406 4.5 7 4.5C6.58594 4.5 6.25 4.8375 6.25 5.25V7H3.75V5.25C3.75 4.8375 3.41563 4.5 3 4.5C2.58437 4.5 2.25 4.8375 2.25 5.25V7L2 6.97188C0.895313 6.97188 0 7.86719 0 8.94375V14.9438C0 15.5531 0.447812 16 1 16H13C13.5522 16 14 15.5522 14 15V8.97188C14 7.89375 13.1031 6.97188 11.9719 6.97188ZM12.5 14.5H1.5V12.2634C2.06625 12.1726 2.57594 11.9406 3 11.6666C3.54187 12.0162 4.23875 12.3344 5 12.3344C5.76125 12.3344 6.45813 12.0159 7 11.6666C7.54187 12.0162 8.23875 12.3344 9 12.3344C9.76125 12.3344 10.4581 12.0159 11 11.6666C11.4206 11.9378 11.9356 12.1878 12.5 12.2859V14.5ZM12.5 10.7437C12.0791 10.6074 11.6941 10.3431 11.4672 10.1631C11.1938 9.94534 10.8059 9.94534 10.5325 10.1631C10.1906 10.4125 9.6 10.8344 9 10.8344C8.4 10.8344 7.78219 10.4134 7.46719 10.1631C7.19375 9.94534 6.80594 9.94534 6.5325 10.1631C6.19062 10.4125 5.6 10.8344 5 10.8344C4.4 10.8344 3.78219 10.4134 3.46719 10.1631C3.19375 9.94534 2.80594 9.94534 2.5325 10.1631C2.30594 10.3156 1.92094 10.6062 1.5 10.7437V9C1.5 8.72431 1.72431 8.5 2 8.5H12C12.2757 8.5 12.5 8.72431 12.5 9V10.7437ZM11 3.47187C11.6903 3.47187 12.25 2.91313 12.25 2.22281C12.25 1.5325 11 0 11 0C11 0 9.75 1.55969 9.75 2.25C9.75 2.94031 10.3094 3.47187 11 3.47187ZM7 3.47187C7.69031 3.47187 8.25 2.91313 8.25 2.22281C8.25 1.5325 7 0 7 0C7 0 5.75 1.55969 5.75 2.25C5.75 2.94031 6.30937 3.47187 7 3.47187ZM3 3.47187C3.69031 3.47187 4.25 2.91313 4.25 2.22281C4.25 1.5325 3 0 3 0C3 0 1.75 1.55969 1.75 2.25C1.75 2.94031 2.30969 3.47187 3 3.47187Z"
    />
  </svg>
</template>
